.userModalForm {
  .ant-form-item-control-input {
    min-height: auto;
  }
  &.ant-modal {
    .ant-modal-content {
      padding: 30px 40px
    }
    .ant-modal-close {
      top: 20px;
      inset-inline-end: 20px;
    }
  }
}


