.error {
  &-layout {
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 100px;
    flex-direction: column;
  }
  &-content {
    margin-bottom: 100px;
    margin-top: 50px;
  }
  &-scroll {
    min-height: 200px;
    max-height: 300px;
    width: 80vw;
    overflow-y: scroll;
    padding: 10px 20px;
    background-color: #ebebeb;
  }
  &-icon {
    font-size: 120px;
    margin-bottom: 40px;
  }
}

