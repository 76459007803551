.plan-fact-block {
  background: #f6f6f6;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(180, 180, 180, 0.16);
  padding: 15px;
  height: 100%;

  .content {
    padding: 0 24px;
    margin-bottom: 20px;
    font-size: 40px;
    font-weight: bold;
    line-height: 1.22;
    //justify-content: center;

    .ant-col {
      font-size: 40px;
    }
  }

  .hints .ant-col {
    font-size: 11px;
    line-height: 1.2;
  }

  &.status-approved {
    background: #daeddb;
  }

  &.status-rejected {
    background: #eebebf;
  }

  &.status-updatedbysystem {
    background: #eebebf;
  }
}

