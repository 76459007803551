.candidate-data-block {
  padding: 30px;
  margin-top: 6px;
  background: #FFFFFF;
  /* Snowflake */

  border: 1px solid #EEEFF0;
  border-radius: 6px;
  h3 {
    text-align: left;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    margin-bottom: 24px;
  }
}
