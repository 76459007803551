.DadataAddressItem {
  .ant-divider-vertical {
    height: calc(100% - 24px);
    margin: 2px 18px 0 0;
  }
}

.ant-select-item-option.red {
  color: red;
}

