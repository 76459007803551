.autocomplete-filter.w-auto {
  width: auto;
}
.autocomplete-filter {
  width: 300px;

  .checkbox-group {
    width: 100%;
    max-height: 265px;
    overflow-y: auto;

    .ant-checkbox-wrapper {
      padding: 0 10px;
      display: flex;
      margin: 0;
      line-height: 32px;
      height: 32px;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }

  .ant-select {
    width: 100%;
  }
}

