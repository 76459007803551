.planningStatus {
  display: inline;
  height: 34px;
  padding: 8px 18px 7px;
  border-radius: 100px;
  background-color: #707070;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
