.modal__wrap {
  padding: 0 20px;
  overflow-y: auto;

  .droppable-list {
    width: 100% ;
    //height: 60px;
    padding: 15px;
    //border: 1px solid #4caf50;
    border-radius: 4px;
    display: flex;
  }
}
.edit-name {
  font-size: 32px;
}
.edit-fill {
  font-size: 28px;
  margin-top: -4px;
  cursor: pointer;
}

.draggable-item {
  width: auto;
}
.workflow-handlers-modal {
  .ant-modal {
    top: 50px;
  }
}
.handler-was-changed-icon {
  color: #faad14;
  font-size: 22px;
}
.popover-btn {
  padding: 0;
}

