.inactive-status {
  padding: 5px;

  .ant-checkbox-wrapper {
    margin-right: 10px;
  }
}
.active-status {
  .ant-checkbox-wrapper {
    margin-right: 10px;
  }
}

