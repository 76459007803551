.animate__animated {
  --animate-duration: .5s;

  &.animate-secondary {
    position: absolute;
    background: #fff;
    z-index: 10;
    width: 100%;
  }
}

