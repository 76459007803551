.Auth {
  .logo {
    width: 100%;
    height: 100vh;
    background: url('@{images-path}/logo-white.png') no-repeat center;
    background-size: 350px, 100%;
  }
  .logo-wrapper {
    background: @blue-color;
  }
  .version {
    position: absolute;
    top: calc(50% + 30px);
    right: calc(50% - 150px);
    color: #fff;
    font-size: 30px;
    background: transparent;
  }
  .title {
    text-align: center;
    font-size: 32px;
    font-weight: normal;
    margin: 0;
    color: @text-color;
  }
  .sub-title {
    margin: 45px auto 10px auto;
    font-size: 15px;
    font-weight: normal;
    text-align: center;
  }
  input {
    height: 55px;
    font-size: 18px;
    //background-color: rgba(4, 161, 249, 0.19)!important;
  }
  .ant-input-affix-wrapper >input.ant-input {
    font-size: 18px;
  }
  input[type='password'] {
    height: 45px;
  }
  .ant-btn.ant-btn-lg {
    height: 55px;
    font-size: 25px;
  }
  .link {
    margin-top: 10px;
    text-align: center;
    a.danger {
      color: @danger-color;
    }
  }
  .auth-input-margin {
    margin: 20px 0 0;
  }
}
.ant-select.auth-role-selector {
  min-width: 200px;
}
@media only screen and (max-width: 575px) {
  .Auth {
    .title {
      font-size: 42px;
    }
    input {
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill::first-line {
        font-size: 28px;
      }
      font-size: 28px;
      height: 80px;
    }
    input[type='password'] {
      height: 70px;
    }
    .ant-input-affix-wrapper >input.ant-input {
      font-size: 28px;
    }
    .logo {
      height: 30vh;
    }
    .logo-wrapper {
      height: 30vh;
      max-width: 100vw;
      width: 100vw;
      flex: unset;
      margin-bottom: 3rem;
    }
    .ant-btn.ant-btn-lg {
      height: 80px;
      font-size: 35px;
    }
    .link {
      font-size: 20px;
    }
  }
}

