.profile-action-loading {
  float: right;
  margin-right: 20px !important;
}

.profile-edit {
  cursor: pointer;
  margin-right: 20px;

  i {
    transition: all 0.3s;
    user-select: none;

    &:hover {
      color: #232323 !important;
    }
  }
}

.profile-close,
.profile-edit-close {
  cursor: pointer;
  margin-right: 20px;

  i {
    transition: all 0.3s;
    user-select: none;

    &:hover {
      color: #232323 !important;
    }
  }
}
.chat-bot {
  .bot-icon {
    min-width: 40px;
  }
  small {
    font-size: 11px;
  }
}

